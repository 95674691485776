<template>
  <div class="py-5 banner" v-if="cross_selling_products.length > 3">
    <b-container fluid="xxl">
      <b-row class="mb-3">
        <b-col>
          <h2 class="mb-1 text-center">{{ $t("crossSelling.heading") }}</h2>
          <div class="f-h3 heading fw-normal text-center">
            {{ $t("crossSelling.subheading") }}
          </div>
        </b-col>
      </b-row>
      <b-row align-v="stretch">
        <b-col cols="12">
          <VueSlickCarousel
            v-bind="settings"
            class="cross-selling-product-slider"
            ref="crossSellingProductCarousel"
          >
            <div
              class="cross-selling-product-wrapper"
              v-for="(cross_selling_product, i) in cross_selling_products"
              :key="i"
            >
              <div class="cross-selling-product-card">
                <div class="card-img-title">
                  <b-img
                    :src="cross_selling_product.details.img"
                    class="w-100"
                  />
                  <h3 class="text-center mx-3">
                    {{ cross_selling_product.details.caption }}
                  </h3>
                </div>
                <b-button
                  @click="
                    openCSProduct(
                      cross_selling_product.type,
                      cross_selling_product.num,
                      cross_selling_product.indication
                    )
                  "
                  variant="secondary"
                  class="f-p1 w-100 product-btn"
                >
                  {{ $t("crossSelling.productButton") }}
                </b-button>
              </div>
            </div>
          </VueSlickCarousel>
          <div
            class="slider-controls-btn"
            v-if="cross_selling_products.length > 4"
          >
            <div class="prev-btn" v-if="cross_selling_products.length > 2">
              <b-button @click="prev()" href="#" variant="link">
                <BIconChevronLeft />
              </b-button>
            </div>
            <div class="next-btn" v-if="cross_selling_products.length > 2">
              <b-button @click="next()" href="#" variant="link">
                <BIconChevronRight />
              </b-button>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { BIconChevronLeft, BIconChevronRight } from "bootstrap-vue";
import VueSlickCarousel from "vue-slick-carousel";

export default {
  name: "ProductDetailCrossSelling",
  props: ["product_num"],
  i18nOptions: { keyPrefix: "productDetail" },
  components: {
    BIconChevronLeft,
    BIconChevronRight,
    VueSlickCarousel,
  },
  data: function () {
    return {
      cross_selling_products: [],
      settings: {
        centerMode: false,
        centerPadding: "0px",
        focusOnSelect: true,
        slidesToShow: 4,
        infinite: false,
        speed: 500,
        swipe: false,
        dots: false,
        arrows: false,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              initialSlide: 0,
              centerPadding: "10px",
              swipe: true,
              dots: false,
            },
          },
        ],
      },
    };
  },
  mounted: function () {
    this.loadCrossSellingProducts();
  },
  watch: {
    product_num: function () {
      this.loadCrossSellingProducts();
    },
  },
  methods: {
    loadCrossSellingProducts() {
      fetch(this.$ZNC_BACKEND_BASE + "/business/v1/product", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          crossSelling: [this.product_num],
          lang: this.$i18next.resolvedLanguage,
        }),
      })
        .then((response) => Promise.all([response.status, response.json()]))
        .then(([status, result]) => {
          if (status == 200) {
            this.cross_selling_products = result;
            this.cross_selling_products.forEach((product) => {
              if (!product.details.caption) {
                product.details.caption =
                  product.type == "Spray"
                    ? product.type + " " + product.indication
                    : product.num;
              }
            });
          } else {
            // ignore
          }
        });
    },
    openCSProduct: function (type, product, indication) {
      this.$EventBus.$emit("gtag", {
        event: "cross-selling",
        schritt: "open-product",
      });
      if (type == "Geschenkgutschein") {
        this.$router.push("/geschenkgutschein");
      } else if (type == "Spray") {
        this.$router.push({
          name: indication,
          params: { fromStart: true },
        });
      } else if (product) {
        this.$router.push({
          name: "ProductView",
          params: { product_num: product },
        });
      }
    },
    next() {
      this.$refs.crossSellingProductCarousel.next();
    },
    prev() {
      this.$refs.crossSellingProductCarousel.prev();
    },
  },
};
</script>

<style lang="scss" scoped>
.cross-selling-product-wrapper {
  height: 100%;
  padding: 0 0.5rem;

  .cross-selling-product-card {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    .card-img-title {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      hyphens: auto;
      -ms-hyphens: auto;
      -webkit-hyphens: auto;
      -moz-hyphens: auto;
    }

    .product-btn {
      background-color: var(--zimply-light-green);
      border-color: var(--zimply-light-green);
      color: var(--zimply-black);

      &:hover {
        background-color: var(--zimply-brown);
        border-color: var(--zimply-brown);
        color: var(--zimply-black);
      }
    }
  }

  @media only screen and (max-width: 768px) {
    padding: 0 0.25rem;
  }
}

@media only screen and (max-width: 768px) {
  .mobile-left {
    padding-right: 5px;
  }

  .mobile-right {
    padding-left: 5px;
  }
}

.fw-normal {
  font-weight: 400;
}

.slider-controls-btn {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  margin-top: 2rem;
}

.prev-btn,
.next-btn {
  background-color: var(--zimply-dark-green);
  border-radius: 10px;

  .btn-link {
    color: var(--zimply-beige);
  }

  &:hover {
    background-color: var(--zimply-brown);

    .btn-link {
      color: var(--zimply-black);
    }
  }
}

.cross-selling-product-slider {
  & > div {
    padding-left: 0 !important;
  }
}
</style>
