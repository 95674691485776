<template>
  <b-container class="ingredients" fluid>
    <h2 class="heading f-h3">{{ $t("ingredients") }}</h2>
    <b-row align-v="stretch">
      <b-col
        cols="4"
        class="p-1"
        v-for="(ingredient, index) in ingredientsToShow"
        :key="index"
      >
        <div class="plant">
          <b-img
            :src="$ZNC_BACKEND_BASE + '/ui/img-essences/' + ingredient.image"
            class="img"
          />
          <div class="f-p3 plant-text">{{ ingredient.name_loc }}</div>
        </div>
      </b-col>
    </b-row>
    <b-button
      @click="$emit('all-ingredients')"
      href="#"
      variant="link"
      class="f-p4 link"
      v-if="
        (this.$isMobile && ingredients.length > 4) || ingredients.length > 6
      "
    >
      {{ $t("allIngredients") }} <BIconArrowRight />
    </b-button>
  </b-container>
</template>

<script>
import { BIconArrowRight } from "bootstrap-vue";

export default {
  name: "ProductDetailIngredientsSmall",
  components: { BIconArrowRight },
  props: ["ingredients", "amount", "md_amount"],
  i18nOptions: { keyPrefix: "productDetail" },
  computed: {
    ingredientsToShow: function () {
      if (this.$isMobile) return this.ingredients.slice(0, this.md_amount);
      return this.ingredients.slice(0, this.amount);
    },
  },
};
</script>

<style lang="scss" scoped>
.ingredients {
  padding-block: 10px;
  .heading {
    margin-left: -15px;
  }
  .plant {
    background-color: var(--zimply-lighter-green);
    border-radius: 10px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    padding-block: 5px;
    padding-inline: 10px;
    hyphens: auto;
    min-height: 55px;
    line-height: 1.2;

    div {
      padding-top: 2px;
    }

    .img {
      height: 30px;
      width: 30px;
      border-radius: 50%;
      margin-right: 10px;
      aspect-ratio: 1/1;
    }

    @media only screen and (min-width: 768px) {
      .plant-text {
        font-size: 1rem;
      }
    }
  }
  .link {
    text-align: end;
    width: calc(100% + 30px);
    margin-inline: -15px;
  }
}
</style>
