<template>
  <div>
    <ProductDetail
      v-if="product"
      :product="product"
      :firstname="firstname"
      :hasInput="false"
    />
  </div>
</template>

<script>
import ProductDetail from "@/components/ProductDetail.vue";

export default {
  name: "ProductView",
  components: {
    ProductDetail,
  },
  props: ["product_num", "firstname"],
  metaInfo: function () {
    return {
      titleTemplate: "ZIMPLY NATURAL - %s",
    };
  },
  data: function () {
    return {
      recipe: null,
      product: null,
    };
  },
  created: async function () {
    await this.$sessionAvailable;
    await this.loadProductInfo();
  },
  watch: {
    "$i18next.resolvedLanguage": async function () {
      await this.loadProductInfo();
    },
  },
  beforeRouteUpdate: async function (to, from, next) {
    if (to.params.product_num !== from.params.product_num) {
      await this.loadProductInfo(to.params.product_num);
      next();
      document
        .getElementById("touch-wrapper")
        .scrollTo({ top: 0, behavior: "smooth" });
    } else {
      next();
    }
  },
  methods: {
    loadProductInfo: async function (product_num = null) {
      fetch(this.$ZNC_BACKEND_BASE + "/business/v1/product", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          product: product_num ?? this.product_num,
          lang: this.$i18next.resolvedLanguage,
        }),
      })
        .then((response) => Promise.all([response.status, response.json()]))
        .then(([status, result]) => {
          if (status == 200) {
            if (result.product) {
              // TODO: consolidate API data structures
              this.product = result.product.spray;
              this.product.recipe = result.recipe;
              this.product.alternative = result.product.mixture;
            } else {
              this.product = result;
            }
            if (this.product.details.bundle) {
              this.product.details.bundle.forEach((article) => {
                if (article.product) {
                  // TODO: consolidate API data structures
                  article.product = article.product.spray;
                  article.product.recipe = article.recipe;
                  article.product.alternative = article.product.mixture;
                } else {
                  article.product = article;
                }
              });
            }
            this.$EventBus.$emit("gtag", {
              event: "konfigurator-schnellbestellung",
              schritt: "produktansicht",
              teilschritt: this.product_num,
            });
          } else {
            if (result.error == "102") {
              this.$bvModal.show("timeout-modal");
              this.$router.replace({
                name: "Start",
              });
            } else {
              this.$EventBus.$emit(
                "show_error",
                this.$t("product.errorUnknownProduct")
              );
              this.$router.replace({
                name: "Start",
                params: { performSearch: this.product_num },
              });
            }
          }
        });
    },
  },
};
</script>
