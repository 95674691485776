<template>
  <b-container class="sprays" fluid>
    <h2 class="heading f-h3">{{ $t("bundleIncludes") }}</h2>
    <b-row align-v="stretch">
      <template v-for="(spray, index) in sprays">
        <b-col
          cols="4"
          class="px-1 py-2"
          v-for="i in spray.amount"
          :key="index + '-' + i"
        >
          <b-button
            class="spray"
            @click="openBundleProduct(spray.type, spray.num, spray.indication)"
          >
            <b-img :src="spray.icon" class="icon" v-if="spray.icon" />
            <div class="f-p3 spray-text">{{ spray.name }}</div>
          </b-button>
        </b-col>
      </template>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "ProductDetailBundleSprays",
  props: ["sprays"],
  i18nOptions: { keyPrefix: "productDetail" },
  methods: {
    openBundleProduct: function (type, product, indication) {
      if (type == "Geschenkgutschein") {
        this.$router.push("/geschenkgutschein");
      } else if (type == "Spray") {
        this.$router.push({
          name: indication,
          params: { fromStart: true },
        });
      } else if (product) {
        this.$router.push({
          name: "ProductView",
          params: { product_num: product },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.sprays {
  padding-block: 10px;

  .heading {
    margin-left: -15px;
  }
  .spray {
    background-color: var(--zimply-lighter-green);
    color: var(--zimply-black);
    text-align: left;
    border: none;
    border-radius: 10px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px;
    hyphens: auto;
    min-height: 55px;
    line-height: 1.2;

    div {
      padding-top: 2px;
    }

    img {
      justify-self: flex-start;
    }

    .icon {
      height: 30px;
      width: 30px;
      margin-right: 10px;
      aspect-ratio: 1/1;
    }

    @media screen and (max-width: 767px) {
      flex-direction: column;

      .icon {
        margin-right: 0;
        margin-bottom: 10px;
      }

      .spray-text {
        text-align: center;
      }
    }

    @media only screen and (min-width: 768px) {
      .spray-text {
        font-size: 1rem;
      }
    }
  }
}
</style>
